/* eslint-disable react/prop-types */
import React from 'react';
import '../../index.css';
import { Outlet, Link, useLocation } from 'react-router-dom';
import styles from './Home.module.css';

function Home() {
  const locationObject = useLocation();
  const location = locationObject.pathname;

  return (
    <div className={styles.homeContainer}>
      <div className={styles.homeNavbar}>
        <div>
          <Link
            to="schepen"
            className={
              location === '/home/schepen'
                ? styles.linkActive
                : styles.linkInactive
            }>
            Schepen
          </Link>
        </div>
        <div>
          <Link
            to="notificaties"
            className={
              location === '/home/notificaties'
                ? styles.linkActive
                : styles.linkInactive
            }>
            Meldingen
          </Link>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Home;
