import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from '@mui/material';
import { getUser } from '../helpers/auth_helper';

function Ships() {
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [controller, setController] = useState({
    pageNumber: 0,
    pageSize: 10
  });
  const [count, setCount] = useState(0);
  const [user, setUser] = useState(null);

  // TODO Both are unused please remove if not needed
  console.log(count);
  console.log(user);

  const getData = async (userData) => {
    const url = `${process.env.REACT_APP_API_GATEWAY_URL}/verwerkings/api/v1/schipLocatie?schipId=1&pageNumber=${controller.pageNumber}&pageSize=${controller.pageSize}`;
    console.log(url);
    console.log(userData);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`
        }
      });

      console.log(response);

      if (response.statusText === 'OK') {
        const data = await response.json();
        console.log(data);
        setItems(data.shipLocatieList);
        setItemsCount(data.totalElements);
        setCount(data.totolPages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser().then((userFromData) => {
      console.log(userFromData);
      if (userFromData) {
        setUser(userFromData);
        getData(userFromData);
      }
    });
  }, [controller]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      pageNumber: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      pageSize: parseInt(event.target.value, 10),
      pageNumber: 0
    });
  };

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>LONGITUDE</TableCell>
            <TableCell>LATITUDE</TableCell>
            <TableCell>SHIP ID</TableCell>
            <TableCell>TIMESTAMP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.longitude}</TableCell>
              <TableCell>{item.longitude}</TableCell>
              <TableCell>{item.schipId}</TableCell>
              <TableCell>{item.timeStamp}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.pageNumber}
        count={itemsCount}
        rowsPerPage={controller.pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

export default Ships;
