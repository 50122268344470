/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';

import jwt_decode from 'jwt-decode';
import { getUser, login } from '../helpers/auth_helper';

function Protected(props) {
  const { Component } = props;

  const isRun = useRef(false);
  const [isAuthenticated, setLoggedIn] = useState(false);
  // Create a state for the wanted role
  const [isAuthority, setIsAuthority] = useState(false);
  const [isShipOwner, setIsShipOwner] = useState(false);

  useEffect(() => {
    if (isRun.current) return;

    isRun.current = true;
    getUser()
      .then((user) => {
        if (!user) {
          setLoggedIn(false);
          login();
        } else {
          setLoggedIn(true);
        }
      })
      .catch((err) => {
        console.log(err);
        login();
      });
  }, []);

  useEffect(() => {
    async function getRole() {
      const user = await getUser();
      if (!user || !user.access_token) {
        return;
      }
      const token = user.access_token;
      const decodedToken = jwt_decode(token);
      const realmAccesssRoles = decodedToken.realm_access.roles;

      // Check if the realmAccessRoles includes the role you need
      if (realmAccesssRoles.includes('AUTHORITY')) {
        // Set the role to true
        setIsAuthority(true);
      }

      // Check if the realmAccessRoles includes the role you need
      if (realmAccesssRoles.includes('shipOwner')) {
        // Set the role to true
        setIsShipOwner(true);
      }
    }

    getRole();
  }, []);

  return isAuthenticated ? (
    // Pass wanted roles as props like underneath
    <Component isAuthority={isAuthority} isShipOwner={isShipOwner} />
  ) : (
    <div>Redirecting to login page...</div>
  );
}

export default Protected;
