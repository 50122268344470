import React from 'react';
import styles from './clearAll.module.css';
import close from '../../images/close.jpg';

// eslint-disable-next-line react/prop-types
function ClearAll({ onClearAll }) {
  return (
    <div className={styles.filterContainer}>
      <div className={styles.group}>
        <button
          className={styles.clearButton}
          type="button"
          onClick={() => {
            onClearAll();
          }}>
          <img src={close} alt="dot" /> Clear All
        </button>
      </div>
    </div>
  );
}

export default ClearAll;
