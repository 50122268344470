import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRedirect } from '../helpers/auth_helper';

const useAuth = () => {
  const isRun = useRef(false);
  const [token, setToken] = useState(null);
  const [isLogin, setLogin] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // TODO remove if unnused
  console.log(token);

  useEffect(() => {
    if (isRun.current) return;

    isRun.current = true;
    loginRedirect()
      .then((userFromRedirect) => {
        console.log(userFromRedirect);
        setUser(userFromRedirect);
        setLogin(true);
        setToken(userFromRedirect.access_token);
        localStorage.setItem('user', JSON.stringify(user));
        navigate(userFromRedirect.state.redirectUrl);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return [isLogin, user];
};

export default useAuth;
