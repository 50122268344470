/* eslint-disable react/prop-types */
import React from 'react';
import './Tooltip.modules.css';

function StandardShipInfo({ markerClickData }) {
  return (
    <div className="info1">
      <div className="card">
        <div className="card-body">
          <h6 className="card-title title">Schip informatie</h6>
          <h6 className="card-text body-head"> {markerClickData.schipRefId}</h6>
          <p className="card-text">
            Latitude- {markerClickData.schipLocation.latitude}.
          </p>
          <p className="card-text">
            Longitude- {markerClickData.schipLocation.longitude}.
          </p>
          <p className="card-text">
            Status- {markerClickData.schipLocation.status}.
          </p>
        </div>
      </div>
    </div>
  );
}

export default StandardShipInfo;
