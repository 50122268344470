/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react';
import '../../index.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import api from '../../helpers/api_interceptor';
import DataTable from '../data_table/DataTable';
import Searchbar from '../searchbar/Searchbar';
import ClearAll from '../clearall/Clearall';
import { getUser, login } from '../../helpers/auth_helper';
import styles from './shipsDataPage.module.css';
import stat from '../../images/status.jpg';

const tableColumns = [
  {
    hideable: true,
    name: 'ID',
    id: 'id'
  },
  {
    hideable: true,
    name: 'SchipRefID',
    id: 'schipRefId'
  },
  {
    hideable: true,
    name: 'Date/Time',
    id: 'dateTime'
  },
  {
    hideable: true,
    name: 'longitude',
    id: 'longitude'
  },
  {
    hideable: true,
    name: 'latitude',
    id: 'latitude'
  },
  {
    hideable: true,
    name: 'Status',
    id: 'status'
  }
];

const initialVisibilityObject = {
  id: true,
  schipRefId: true,
  dateTime: true,
  longitude: true,
  latitude: true,
  status: true
};

function NotificationsDataPage() {
  const navigate = useNavigate();

  const [dataSet, setDataSet] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const requestBody = useRef({
    schipRefId: null,
    status: undefined
  });

  const [visibilityObject, setVisibilityObject] = useState(
    initialVisibilityObject
  );
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const currentPage = useRef(0);

  const handleToggleStatusDropdown = () => {
    setShowStatusDropdown(!showStatusDropdown);
  };

  async function filterPost(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;
    if (!user) {
      // User is not logged in, initiate login
      login();
      return;
    }
    const apiUrl = `/verwerkings/api/v1/schipLocation?pageNumber=${pageForUrl}&pageSize=10`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers
      });
      setDataSet(response.data.responseList);
      setTotalElements(response.data.totalElements);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleSearch = async (name) => {
    requestBody.current = {
      ...requestBody.current,
      schipRefId: name
    };
    await filterPost();
  };

  const handleSelect = async (value) => {
    requestBody.current = {
      ...requestBody.current,
      status: value
    };
    handleToggleStatusDropdown();
    await filterPost();
  };

  const toggleState = () => {
    setVisibilityObject({ ...initialVisibilityObject });

    requestBody.current = {
      schiprefid: null,
      status: undefined
    };
    window.location.reload();
  };

  useEffect(() => {
    filterPost();
  }, []);

  return (
    <div className="w-100">
      <DataTable
        data={dataSet}
        columns={tableColumns}
        totalItems={totalElements}
        pageNumber={currentPage.current}
        onLinkClick={(rowItem) => {
          const dateFormat = 'DD-MM-YYYY';
          const dateFormat1 = 'YYYY-MM-DD';
          const urlStartDate = moment.utc(rowItem.dateTime).format(dateFormat);
          const urlEndDate = moment
            .utc(rowItem.dateTime)
            .add(1, 'days')
            .format(dateFormat);
          const startDateRange = rowItem.dateTime;
          const endDateRange = rowItem.dateTime;
          const urlStartDateRange = moment
            .utc(startDateRange)
            .format(dateFormat1);
          const urlEndDateRange = moment.utc(endDateRange).format(dateFormat1);
          navigate(
            `/map/${rowItem.schipId}/${urlStartDate}/${urlEndDate}/${rowItem.schipRefId}/${urlStartDateRange}/${urlEndDateRange}`
          );
        }}
        onPageChange={(page) => {
          currentPage.current = page;
          filterPost(page);
        }}
        visibilityObject={visibilityObject}>
        <div className={`${styles.layersContainer}`}>
          <div className={`${styles.statusButtonContainer}`}>
            <button
              className={`${styles.layersDropdown} ${
                showStatusDropdown ? styles.layerFilterOpen : ''
              }`}
              type="button"
              onClick={handleToggleStatusDropdown}>
              <img className={styles.inputImage} src={stat} alt="column" />
              {requestBody.current.status || 'Status'}
            </button>
            {showStatusDropdown && (
              <div className={styles.layerFilterDropdownItemContainer}>
                <div className={styles.layersFilterItem}>
                  <button
                    type="button"
                    onClick={() => handleSelect('VAREN')}
                    className={styles.statusDropdownButton}>
                    <label htmlFor="confirmed">VAREN</label>
                  </button>
                </div>
                <div className={styles.layersFilterItem}>
                  <button
                    type="button"
                    onClick={() => handleSelect('VISSEN')}
                    className={styles.statusDropdownButton}>
                    <label htmlFor="declined">VISSEN</label>
                  </button>
                </div>
                <div className={styles.layersFilterItem}>
                  <button
                    type="button"
                    onClick={() => handleSelect('INHAVEN')}
                    className={styles.statusDropdownButton}>
                    <label htmlFor="registered">IN HAVEN</label>
                  </button>
                </div>
                <div className={styles.layersFilterItem}>
                  <button
                    type="button"
                    onClick={() => handleSelect('ANCHOR')}
                    className={styles.statusDropdownButton}>
                    <label htmlFor="anchored">ANCHOR</label>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <ClearAll onClearAll={() => toggleState()} />
        <Searchbar
          currentValue={requestBody.current.schipRefId}
          onSearch={(name) => {
            handleSearch(name);
          }}
        />
      </DataTable>
    </div>
  );
}

export default NotificationsDataPage;
