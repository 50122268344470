import React, { useEffect } from 'react';
import {
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useNavigate
} from 'react-router-dom';
import Navbar from './components/Navbar';
import SigninRedirect from './pages/SigninRedirect';
import Ships from './components/Ships';
import Map from './components/Map';
import Protected from './components/Protected';
import Test from './pages/Testing';
import Home from './components/home/Home';
import ShipsDataPage from './components/ships_data_page/ShipsDataPage';
import NotificationsDataPage from './components/notifications_data_page/NotificationsDataPage';
// import ReportDataPage from './components/report_data_page/ReportDataPage';

function homePageRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/home/schepen');
  }, []);

  return <div />;
}

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route exact path="/" element={<Root />}>
          <Route
            exact
            path="/ships"
            element={<Protected Component={Ships} />}
          />
          <Route path="home" element={<Protected Component={Home} />}>
            <Route
              path="schepen"
              element={<Protected Component={ShipsDataPage} />}
            />
            <Route
              exact
              path="notificaties"
              element={<Protected Component={NotificationsDataPage} />}
            />
            {/* <Route
              exact
              path="rapport"
              element={<Protected Component={ReportDataPage} />}
            /> */}
          </Route>
          <Route
            exact
            path="/map/:urlid?/:urlstrtDate?/:urlenDate?/:urlschipRefId?/:urlStartRangeDate?/:urlEndRangeDate?"
            element={<Protected Component={Map} />}
          />
          <Route
            exact
            path="/"
            element={<Protected Component={homePageRedirect} />}
          />
        </Route>
        <Route exact path="/signin-redirect" element={<SigninRedirect />} />
        <Route exact path="/test" element={<Test />} />
      </>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

function Root() {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
}

export default App;
