/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import '../index.css';
import img1 from '../images/blueRectangle.jpg';
import img2 from '../images/logo.png';

function Test() {
  return (
    <>
      <div className="test1">
        <img className="blueRectangle" src={img1} alt="" />
        <img className="logo" src={img2} alt="" />

        <h3 className="header">Rijksoverheid</h3>
      </div>

      <div className="test2">
        <h2 className="header-2">Dataplatform</h2>
      </div>

      <div className="test3">
        <h2 className="header-3">Inloggen</h2>
        <div className="formdiv">
          <form action="/test" method="post">
            <div className="mb-3 formc">
              <label htmlFor="basic-url" className="form-label">
                Gebruikersnaam
              </label>
              <div className="input-group">
                <input
                  type="text"
                  placeholder=""
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                />
              </div>
            </div>

            <div className="mb-3 formc">
              <label htmlFor="basic-url" className="form-label">
                Wachtwoord
              </label>
              <div className="input-group">
                <input
                  type="text"
                  placeholder=""
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3 basic-addon4"
                />
              </div>
            </div>
            <div className="form-text ftext" id="basic-addon4">
              <a className="forgotpass" href="/">
                Wachtwoord vergeten
              </a>
            </div>
            <div>
              <button type="button" className="btn login-btn">
                Inloggen
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Test;
