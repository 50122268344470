/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import FileSaver from 'file-saver';

import api from '../../helpers/api_interceptor';
import { getUser, login } from '../../helpers/auth_helper';
import stat from '../../images/status.jpg';
import overRideIcon from '../../images/override.png';
import closeIcon from '../../images/close.jpg';
import '../../index.css';
import ClearAll from '../clearall/Clearall';
import DataTable from '../data_table/DataTable';
import Datefilter from '../datefilter/Datefilter';
import Searchbar from '../searchbar/Searchbar';
import styles from './notificationsDataPage.module.css';
import Modal from '../modal/modal';
import overrideDisabled from '../../images/overrideDisabled.png';

const getTableColumns = (onClickFunction, isAuthority) => [
  {
    hideable: true,
    name: 'ID',
    id: 'id'
  },
  {
    hideable: true,
    name: 'SchipRefID',
    id: 'schipRefId'
  },
  {
    hideable: true,
    name: 'Date/Time',
    id: 'dateTime'
  },
  {
    hideable: true,
    name: 'longitute',
    id: 'longitude'
  },
  {
    hideable: true,
    name: 'latitude',
    id: 'latitude'
  },
  {
    hideable: true,
    name: 'Status',
    id: 'status'
  },

  {
    imageSource: overRideIcon,
    name: 'Override',
    id: 'override',
    hideable: false,
    hasIcon: true,
    onClickAction: isAuthority ? onClickFunction : () => {},
    disabledIcon: overrideDisabled
  }
];

const initialVisibilityObject = {
  id: true,
  schipRefId: true,
  dateTime: true,
  longitude: true,
  latitude: true,
  status: true
};

function NotificationsDataPage({ isAuthority, isShipOwner }) {
  // Check roles by accepting them in the props of the component

  console.log(isShipOwner);
  const navigate = useNavigate();

  const [dataSet, setDataSet] = useState([]);
  const [visibilityObject, setVisibilityObject] = useState(
    initialVisibilityObject
  );
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(undefined);
  const [status, setStatus] = useState('');
  const [textAreaValue, setTextArea] = useState('');
  const [totalElements, setTotalElements] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const requestBody = useRef({
    schipRefId: null,
    status: undefined,
    startDate: '',
    endDate: ''
  });

  const exportBody = useRef({
    schipId: '',
    startDate: '',
    endDate: ''
  });

  const currentPage = useRef(0);

  const toggleModal = (data) => {
    setIsModalVisible((prev) => {
      if (prev === true) {
        setModalData(undefined);
        return !prev;
      }

      setModalData(data);
      return !prev;
    });
  };

  const handleToggleStatusDropdown = () => {
    setShowStatusDropdown(!showStatusDropdown);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleTextAreaChange = (event) => {
    setTextArea(event.target.value);
  };

  const filterChange = () => {
    currentPage.current = 0;
  };

  async function filterPost(page) {
    const user = await getUser();
    const pageForUrl = page || currentPage.current;
    if (!user) {
      login();
      return;
    }
    const apiUrl = `/verwerkings/api/v1/meldingen?pageNumber=${pageForUrl}&pageSize=10`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`
    };

    try {
      const response = await api.post(apiUrl, requestBody.current, {
        headers
      });
      setDataSet(response.data.responseList);
      setTotalElements(response.data.totalElements);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const toggleLoading = (loading, message) => {
    setIsLoading(loading);
    setLoadingMessage(message);
  };

  async function exportReport() {
    const user = await getUser();

    if (!user) {
      login();
      return;
    }
    toggleLoading(true, 'Downloading...');
    const apiUrl = `/verwerkings/api/v1/report`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`
    };

    try {
      const response = await api.post(apiUrl, exportBody.current, {
        headers,
        responseType: 'blob'
      });
      const format = 'YYYY-MM-DD';
      const csvFileName = `${exportBody.current.schipRefId}_van_${moment(
        requestBody.current.startDate
      ).format(format)}_tot_${moment(requestBody.current.endDate).format(
        format
      )}.csv`;

      FileSaver.saveAs(
        new Blob([response.data], { type: 'text/csv' }),
        csvFileName
      );

      toggleLoading(false, '');
    } catch (error) {
      toggleLoading(false, '');
      console.error('Error fetching data:', error);
    }
  }

  async function updateStatus() {
    const user = await getUser();
    if (!user) {
      login();
      return;
    }
    const apiUrl = `verwerkings/api/v1/meldingen`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`
    };

    const req = {
      overtredingId: modalData.id,
      status,
      comment: textAreaValue
    };

    try {
      await api.put(apiUrl, req, {
        headers
      });
      toggleModal();
      await filterPost();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const postStatusUpdate = (event) => {
    event.preventDefault();
    updateStatus();
  };

  const handleSearch = async (name) => {
    requestBody.current = {
      ...requestBody.current,
      schipRefId: name
    };
    filterChange();
    await filterPost();
  };

  const handleDateSearch = async (selectedRange) => {
    requestBody.current = {
      ...requestBody.current,
      startDate: selectedRange[0],
      endDate: selectedRange[1]
    };
    filterChange();
    await filterPost();
  };

  const handleSelect = async (value) => {
    requestBody.current = {
      ...requestBody.current,
      status: value
    };
    handleToggleStatusDropdown();
    if (value !== 'Status') {
      filterChange();
    }
    await filterPost();
  };

  const toggleState = () => {
    setVisibilityObject({ ...initialVisibilityObject });

    window.location.reload();
  };

  const now = moment();
  const todayDate = now.format('YYYY-MM-DD');

  useEffect(() => {
    requestBody.current = {
      ...requestBody.current,
      startDate: `${todayDate} 00:00:00 UTC`,
      endDate: `${todayDate} 23:59:59 UTC`
    };
    filterPost();
  }, []);

  const exportFunction = async (item) => {
    const format = 'YYYY-MM-DD';
    exportBody.current = {
      ...exportBody.current,
      schipId: item.schipId,
      startDate: moment(requestBody.current.startDate).format(format),
      endDate: moment(requestBody.current.endDate).format(format),
      schipRefId: item.schipRefId
    };
    await exportReport();
  };
  return (
    <div className={`w-100 ${styles.pageContainer}`}>
      <DataTable
        data={dataSet}
        totalItems={totalElements}
        pageNumber={currentPage.current}
        rowIconColumnDisabled={(rowItem) => rowItem.status === 'Afgewezen'}
        exportClick={exportFunction}
        columns={getTableColumns(toggleModal, isAuthority)}
        onLinkClick={(rowItem) => {
          const dateFormat = 'DD-MM-YYYY';
          const dateFormat1 = 'YYYY-MM-DD';
          const urlStartDate = moment.utc(rowItem.dateTime).format(dateFormat);
          const urlEndDate = moment
            .utc(rowItem.dateTime)
            .add(1, 'days')
            .format(dateFormat);
          const startDateRange = requestBody.current.startDate;
          const endDateRange = requestBody.current.endDate;
          const urlStartDateRange = moment
            .utc(startDateRange)
            .format(dateFormat1);
          const urlEndDateRange = moment.utc(endDateRange).format(dateFormat1);
          navigate(
            `/map/${rowItem.schipId}/${urlStartDate}/${urlEndDate}/${rowItem.schipRefId}/${urlStartDateRange}/${urlEndDateRange}`
          );
        }}
        onPageChange={(page) => {
          currentPage.current = page;
          filterPost(page);
        }}
        visibilityObject={visibilityObject}>
        <div className={`${styles.layersContainer}`}>
          <div className={`${styles.statusButtonContainer}`}>
            <button
              className={`${styles.layersDropdown} ${
                showStatusDropdown ? styles.layerFilterOpen : ''
              }`}
              type="button"
              onClick={handleToggleStatusDropdown}>
              <img className={styles.inputImage} src={stat} alt="column" />
              {requestBody.current.status || 'Status'}
            </button>
            {showStatusDropdown && (
              <div className={styles.layerFilterDropdownItemContainer}>
                <div className={styles.layersFilterItem}>
                  <button
                    type="button"
                    onClick={() => handleSelect('Bevestigd')}
                    className={styles.statusDropdownButton}>
                    <label htmlFor="confirmed">Bevestigd</label>
                  </button>
                </div>
                <div className={styles.layersFilterItem}>
                  <button
                    type="button"
                    onClick={() => handleSelect('Afgewezen')}
                    className={styles.statusDropdownButton}>
                    <label htmlFor="declined">Afgewezen</label>
                  </button>
                </div>
                <div className={styles.layersFilterItem}>
                  <button
                    type="button"
                    onClick={() => handleSelect('Geregistreerd')}
                    className={styles.statusDropdownButton}>
                    <label htmlFor="registered">Geregistreerd</label>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className={styles.filterContainer}>
          <div className={styles.inputGroup}>
            <img className={styles.inputImage} src={stat} alt="dot" />
            <select
              id="filterOptions"
              className={styles.input}
              value={requestBody.current.status}
              onChange={handleSelect}>
              <option value="default" className={styles.input}>
                Status
              </option>
              <option value="Bevestigd">Bevestigd</option>
              <option value="Afgewezen">Afgewezen</option>
              <option value="Geregistreerd">Geregistreerd</option>
            </select>
          </div>
        </div> */}
        <Datefilter
          // currentValue={{
          //   startDate: requestBody.current.startDate,
          //   endDate: requestBody.current.endDate
          // }}
          onSearch={(selectedRange) => {
            handleDateSearch(selectedRange);
          }}
        />
        <ClearAll onClearAll={() => toggleState()} />
        <Searchbar
          currentValue={requestBody.current.schipRefId}
          onSearch={(name) => {
            handleSearch(name);
          }}
        />
      </DataTable>
      {isLoading && (
        <div className={styles.loadingStyle}>
          <div className={styles.loadingSpinStyle} />
          <p>{loadingMessage}</p>
        </div>
      )}
      {isModalVisible && (
        <Modal isOpen={isModalVisible}>
          <form onSubmit={postStatusUpdate}>
            <div className={styles.modalBody}>
              <div className={styles.modalTitle}>
                <div className={styles.titleContainer}>
                  <img src={overRideIcon} />
                  <span>Status aanpassen</span>
                </div>
                <button type="button" onClick={() => toggleModal()}>
                  <img src={closeIcon} />
                </button>
              </div>
              <div className={styles.modalSubTitle}>
                <div className={styles.modalShipName}>
                  {modalData.schipRefId}
                </div>
                <div>
                  Longitude {modalData.longitude} / Latitude{' '}
                  {modalData.latitude}
                </div>
              </div>
              <div className={styles.modalFormContainer}>
                <div className={styles.modalFormOption}>
                  <input
                    type="radio"
                    id="bevestigd"
                    checked={status === 'Bevestigd'}
                    onChange={handleStatusChange}
                    value="Bevestigd"
                  />
                  <label className={styles.optionConfirmed} htmlFor="bevestigd">
                    Bevestigd
                  </label>
                </div>
                <div className={styles.modalFormOption}>
                  <input
                    type="radio"
                    id="afgewezen"
                    checked={status === 'Afgewezen'}
                    onChange={handleStatusChange}
                    value="Afgewezen"
                  />
                  <label className={styles.optionDeclined} htmlFor="afgewezen">
                    Afgewezen
                  </label>
                </div>
                <div className={styles.modalFormOption}>
                  <input
                    type="radio"
                    id="geregistreerd"
                    checked={status === 'Geregistreerd'}
                    onChange={handleStatusChange}
                    value="Geregistreerd"
                  />
                  <label
                    className={styles.optionRegistered}
                    htmlFor="geregistreerd">
                    Geregistreerd
                  </label>
                </div>
                <label className={styles.commentary}>Opmerking</label>
                <textarea
                  onChange={handleTextAreaChange}
                  type="text"
                  placeholder="voer hier uw opmerking in"
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                className={styles.modalCancelButton}
                onClick={() => toggleModal()}>
                Sluiten
              </button>
              <button type="submit" className={styles.modalConfirmButton}>
                Opslaan
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}

export default NotificationsDataPage;
