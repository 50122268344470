import { UserManager } from 'oidc-client';

const settings = {
  authority: `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_REALM}`,
  client_id: 'frontend_client',
  redirect_uri: `${window.location.origin}/signin-redirect`,
  response_type: 'code',
  scope: 'openid profile'
};

const userManager = new UserManager(settings);

export const getUser = () => userManager.getUser();

export const login = () => {
  const redirectUrl = window.location.pathname;
  return userManager.signinRedirect({ state: { redirectUrl } });
};

export const logout = () => userManager.signoutRedirect();

export const loginRedirect = () => userManager.signinRedirectCallback();
// return userManager.signinRedirectCallback();
