/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import alert from '../../images/alert.png';
import './Tooltip.modules.css';

function AlertShipInfo({ markerClickData }) {
  const convertTo12HourFormat = (dateToConvert) => {
    
    const [datePart, timePart] = dateToConvert.split(' ');
    const [hour, minute] = timePart.split(':');

  
    const period = parseInt(hour) >= 12 ? 'PM' : 'AM';

    
    const convertedHour = parseInt(hour) % 12 || 12;

    
    const newTime = `${convertedHour}:${minute} ${period}`;

   
    console.log(datePart);
    return newTime;
    
  };

  

  return (
    <div className="info2">
      <div className="card cardStyle">
        <div className="card-body">
          <h6 className="card-title title">Schip informatie</h6>
          <h6 className="card-text body-head"> {markerClickData.schipRefId}</h6>
          <p className="card-text">
            Latitude- {markerClickData.schipLocation.latitude}.
          </p>
          <p className="card-text">
            Longitude- {markerClickData.schipLocation.longitude}.
          </p>
          <p className="card-text">
            Status- {markerClickData.schipLocation.status}.
          </p>
          {markerClickData.overtredingList.map((overtreding, index) => {
            const dateToShow =convertTo12HourFormat(overtreding.dateTime);

            return (
              <div className="alert row" key={index}>
                <div className="col-lg-1 col-md-1 col-sm-1">
                  <img className="alertimg " src={alert} alt="Alert" />
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7">
                  <h6>Alert!</h6>
                  <p className="warning-text text-2">
                    This ship entered a restricted area.
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  {dateToShow && (
                    <p>
                      {dateToShow}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AlertShipInfo;
