/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datepicker/dist/react-datepicker.css';
import dotImage from '../../images/dot.png';
import styles from './Searchbar.module.css';

function Searchbar({ onSearch, currentValue }) {
  const [shipName, setShipName] = useState('');

  const handleSearchInputChange = (event) => {
    setShipName(event.target.value);
  };

  const handleSearchFormSubmit = (event) => {
    event.preventDefault();
    onSearch(shipName);
  };

  useEffect(() => {
    if (currentValue !== shipName) {
      setShipName(currentValue);
    }
  }, [currentValue]);

  return (
    <form className="d-flex" onSubmit={handleSearchFormSubmit}>
      <div className={styles.inputGroup}>
        <img className={styles.inputImage} src={dotImage} alt="dot" />
        <input
          className={styles.input}
          type="text"
          placeholder="Zoeken"
          value={shipName}
          onChange={handleSearchInputChange}
          aria-label="Zoeken"
        />
      </div>
    </form>
  );
}

export default Searchbar;
