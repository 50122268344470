/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { getUser, login, logout } from '../helpers/auth_helper';
import mapIcon from '../images/mapNavbar.png';
import boatIcon from '../images/navbar_boats.png';
import notificationIcon from '../images/navbar_meldingen.png';
import reportIcon from '../images/navbar_reports.png';

import logo1 from '../images/logo1.png';
import '../index.css';

function Navbar() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    getUser().then((userFromData) => {
      if (userFromData) {
        setUser(userFromData);
      }
    });
  }, []);

  return (
    <nav className="navbar bg-body-light">
      <div className="container navDiv">
        <a className="navbar-brand" href="#">
          <img src={logo1} className="navLogo " alt="Bootstrap" />
        </a>
        <div className="links d-flex">
          {/* <a href="/">Home</a> */}
          {user && (
            <>
              <a href="/home/schepen">
                <img src={boatIcon} alt="Blue boat icon" />
              </a>
              <a href="/home/notificaties">
                <img src={notificationIcon} alt="Blue warning icon" />
              </a>
              <a href="/home/rapport">
                <img src={reportIcon} alt="Blue graph icon" />
              </a>
              <a href="/map">
                <img src={mapIcon} alt="Blue map icon" />
              </a>
              <a href="#" onClick={logout}>
                Uitloggen
              </a>
            </>
          )}
          {!user && (
            <a href="#" onClick={login}>
              Inloggen
            </a>
          )}
        </div>
      </div>
    </nav>

    // <nav className="navbar">
    //     <h1>Dataplatform</h1>

    //     <div className="links">

    //        {/* <a href="/">Home</a> */}
    //        { user && <a href="/ships">Ships</a> }
    //        <a href="/map">Map</a>
    //        {!user &&  <a href="#" onClick={login}>Login</a>}

    //        {user &&  <a href="#" onClick={logout}>Logout</a>}

    //     </div>
    //     <span style={{color:'#f1356d'}}>
    //      {user &&  <p>{user.profile.email}</p>}
    //     </span>

    // </nav>
  );
}

export default Navbar;
