import moment from 'moment';
import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datepicker/dist/react-datepicker.css';
import cal from '../../images/calendar.png';
import styles from './dateFilter.module.css';

// eslint-disable-next-line react/prop-types
function DateFilter({ onSearch }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDateRangeChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);
    const format1 = 'YYYY-MM-DD';
    const sDate = moment(ranges.selection.startDate).format(format1);
    const eDate = moment(ranges.selection.endDate).format(format1);

    const stDate = `${sDate} 00:00:00 UTC`;
    const edDate = `${eDate} 23:59:59 UTC`;
    onSearch([stDate, edDate]);
  };

  const handleDateFormSubmit = (event) => {
    event.preventDefault();
    onSearch(selectedDateRange);
  };

  const formattedStartDate = selectedDateRange[0].startDate.toDateString();
  const formattedEndDate = selectedDateRange[0].endDate.toDateString();
  const selectedRangeLabel = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    <div className={styles.filterContainer}>
      <form className="date-range-form" onSubmit={handleDateFormSubmit}>
        <div className={styles.inputGroup}>
          <div>
            <button
              className={styles.inputGroupButton}
              type="button"
              onClick={toggleDropdown}>
              <img className={styles.inputImage} src={cal} alt="calendar" />
              {selectedRangeLabel}
            </button>
          </div>

          {dropdownOpen && (
            <div className="date-picker-container">
              <DateRange
                ranges={selectedDateRange}
                onChange={handleDateRangeChange}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default DateFilter;
