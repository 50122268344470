/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  GoogleApiWrapper,
  InfoWindow,
  Map,
  Marker,
  Polygon,
  Polyline
} from 'google-maps-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import api from '../helpers/api_interceptor';
import { getUser, login } from '../helpers/auth_helper';
import calendarImage from '../images/calendar.png';
import portImage from '../images/harbours.png';
import layersImage from '../images/layers.png';
import natura200Image from '../images/natura2000.png';
import routesImage from '../images/routesfilter.png';
// import ship from '../images/ship.png';
import shipImage from '../images/shipfilter.png';
import '../index.css';
import styles from './map.module.css';
import Searchbar from './searchbar/Searchbar';
import StandardShipInfo from './tooltip/tooltip';
import AlertShipInfo from './tooltip/alertTooltip';
// import lightBlueDot from '../images/lightBlueDot.jpg';
import blueDot from '../images/lightBlueDotPng.png';
// import boat from '../images/navbar_boats.png';
// import dot from '../images/dot.png';

function Maps(props) {
  const {
    urlid,
    urlstrtDate,
    urlenDate,
    urlschipRefId,
    urlStartRangeDate,
    urlEndRangeDate
  } = useParams();
  const [items, setItems] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: urlStartRangeDate ? new Date(urlStartRangeDate) : new Date(),
      endDate: urlEndRangeDate ? new Date(urlEndRangeDate) : new Date(),
      key: 'selection'
    }
  ]);

  const now = moment();
  const todayDate = now.format('YYYY-MM-DD');

  const sendDateRange = async (startDate, endDate) => {
    if (!startDate || !endDate) {
      const req1 = {
        startDate: `${todayDate} 00:00:00 UTC`, // Set startDate to todayDate
        endDate: `${todayDate} 23:59:59 UTC` // Set endDate to todayDate
      };
      const url1 = `${process.env.REACT_APP_API_GATEWAY_URL}/verwerkings/api/v1/schipLocation/filterAll?pageNumber=0&pageSize=1`;

      try {
        const user = await getUser();

        if (!user) {
          // User is not logged in, initiate login
          login();
          return;
        }

        const response = await api.post(url1, req1, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.access_token}`
          }
        });

        if (response.status === 200) {
          const { data } = response;
          setResponseData(data);
        }
      } catch (error) {
        console.error('Error sending date range:', error);
      }
    } else {
      const format1 = 'YYYY-MM-DD';

      const sDate = moment(startDate).format(format1);
      const eDate = moment(endDate).format(format1);
      const url = `${process.env.REACT_APP_API_GATEWAY_URL}/verwerkings/api/v1/schipLocation/filterAll?pageNumber=0&pageSize=1`;
      const req = {
        startDate: `${sDate} 00:00:00 UTC`,
        endDate: `${eDate} 23:59:59 UTC`
      };

      try {
        const user = await getUser();

        if (!user) {
          // User is not logged in, initiate login
          login();
          return;
        }

        const response = await api.post(url, req, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.access_token}`
          }
        });

        if (response.status === 200) {
          const { data } = response;
          setResponseData(data);
        }
      } catch (error) {
        console.error('Error sending date range:', error);
      }
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDateRangeChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);
  };

  const handleDateFormSubmit = (event) => {
    event.preventDefault();

    const { startDate } = selectedDateRange[0];
    const { endDate } = selectedDateRange[0];

    // Check if both start date and end date are selected
    if (startDate && endDate) {
      sendDateRange(startDate, endDate); // Call the sendDateRange function
    }
  };

  const postSearchData = async (name) => {
    const { startDate } = selectedDateRange[0];
    const { endDate } = selectedDateRange[0];

    const format1 = 'YYYY-MM-DD';

    const sDate = moment(startDate).format(format1);
    const eDate = moment(endDate).format(format1);

    if (name.trim() !== '') {
      const url = `/verwerkings/api/v1/schipLocation/filterAll?pageNumber=0&pageSize=1`; // Replace with your search API endpoint
      const req = {
        schipName: name,
        startDate: `${sDate} 00:00:00 UTC`,
        endDate: `${eDate} 23:59:59 UTC`
      };

      try {
        const user = await getUser();

        if (!user) {
          // User is not logged in, initiate login
          login();
          return;
        }

        const response = await api.post(url, req, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.access_token}`
          }
        });

        if (response.status === 200) {
          const { data } = response;
          setResponseData(data);
        }
      } catch (error) {
        console.error('Error sending date range:', error);
      }
    }
  };

  const handleSearchFormSubmit = (name) => {
    postSearchData(name);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const formattedStartDate = selectedDateRange[0].startDate.toDateString();
  const formattedEndDate = selectedDateRange[0].endDate.toDateString();
  const selectedRangeLabel = `${formattedStartDate} - ${formattedEndDate}`;

  useEffect(() => {
    async function fetchData() {
      const user = await getUser();

      if (!user) {
        // User is not logged in, initiate login
        login();
        return;
      }

      const apiUrl = `/verwerkings/api/v1/port`;
      const headers = {
        Authorization: `Bearer ${user.access_token}`
      };

      try {
        const response = await api.get(apiUrl, { headers });
        setItems(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'INHAVEN':
        return '#00FF00'; // Green
      case 'VAREN':
        return '#ffa500'; // Orange
      case 'VISSEN':
        return '#FF0000'; // Red
      case 'ANCHOR':
        return '#0000FF'; // Blue
      default:
        return '#000000'; // Default to black if status is not recognized
    }
  };

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow] = useState(true);
  const [markerClickData, setMarkerClickData] = useState([]);
  const markerClick = async (point) => {
    const url = `${process.env.REACT_APP_API_GATEWAY_URL}/verwerkings/api/v1/schipLocation/overtreding/${point.id}`;
    const user = await getUser();
    if (!user) {
      // User is not logged in, initiate login
      login();
      return;
    }

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${user.access_token}`
        }
      });

      if (response.ok) {
        const data = await response.json();

        setMarkerClickData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderCoordinates = () =>
    responseData.map((data, index) => (
      <Marker
        icon={{
          path: 'M-1.547 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
          fillColor: data.violationOccurred ? '#EF1409' : '#01689B',
          fillOpacity: 1,
          strokeWeight: 0,
          rotation:
            index + 1 < responseData.length
              ? responseData[index + 1].direction - 180
              : data.direction - 180,
          scale: 1
        }}
        key={data.id}
        position={{ lat: data.latitude, lng: data.longitude }}
        name={data.schipRefId}
        onClick={(_props, markerToSet) => {
          markerClick(data);
          setSelectedMarker(data);
          setActiveMarker(markerToSet);
        }}
        status={data.status}
      />
    ));
  const renderLinesForShips = () => {
    const shipPaths = {};
    let currentShipId = null;

    responseData.forEach((data) => {
      if (currentShipId !== data.schipId) {
        currentShipId = data.schipId;
        shipPaths[currentShipId] = [];
      }
      shipPaths[currentShipId].push({
        lat: data.latitude,
        lng: data.longitude,
        status: data.status
      });
    });

    // Create a separate Polyline for each ship's path
    return Object.keys(shipPaths).map((shipId) => {
      const points = shipPaths[shipId];
      const colorSegments = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < points.length - 1; i++) {
        const startData = points[i];
        const endData = points[i + 1];

        const color = getStatusColor(startData.status);
        colorSegments.push({ color, path: [startData, endData] });
      }

      return colorSegments.map((segment, index) => (
        <Polyline
          // eslint-disable-next-line react/no-array-index-key
          key={`${shipId}-${index}`}
          path={[segment.path[0], segment.path[1]]}
          options={{
            strokeColor: segment.color,
            strokeOpacity: 0.5,
            strokeWeight: 0,
            icons: [
              {
                icon: {
                  path: 'M 0,-1 0,1',
                  strokeOpacity: 1,
                  scale: 4
                },
                offset: '0',
                repeat: '30px'
              }
            ]
          }}
        />
      ));
    });
  };

  const [showRestrictedAreas, setShowRestrictedAreas] = useState(false);
  const [showPorts, setShowPorts] = useState(false);
  const [showRoutes, setShowRoutes] = useState(false);
  const [showShips, setShowShips] = useState(false);
  const [polygonData, setPolygonData] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleSelectOption = (option) => {
    if (option === 'show-restricted') {
      setShowRestrictedAreas(!showRestrictedAreas);
    } else if (option === 'show-ports') {
      setShowPorts(!showPorts);
    } else if (option === 'show-routes') {
      setShowRoutes(!showRoutes);
    } else if (option === 'show-ships') {
      setShowShips(!showShips);
    } else {
      setShowRestrictedAreas(false);
      setShowPorts(false);
    }
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    const restrictedPolygon = async () => {
      const url = `${process.env.REACT_APP_API_GATEWAY_URL}/verwerkings/api/v1/multiPolygon`;
      const user = await getUser();
      if (!user) {
        // User is not logged in, initiate login
        login();
        return;
      }

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${user.access_token}`
          }
        });

        if (response.ok) {
          const data = await response.json();

          setPolygonData(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    restrictedPolygon();
  }, []);

  const renderRestrictedAreas = () => {
    if (showRestrictedAreas) {
      return polygonData.map((polygon, index) => (
        <Polygon
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          path={polygon}
          options={{
            fillColor: 'blue',
            fillOpacity: 0.4,
            strokeColor: 'red',
            strokeOpacity: 1,
            strokeWeight: 2
          }}
        />
      ));
    }
    return null;
  };
  const mapOptions = {
    mapTypeControlOptions: {
      mapTypeIds: ['terrain', 'roadmap', 'satellite', 'hybrid', 'labels']
    },
    styles: [
      {
        featureType: 'all',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }
    ]
  };

  const renderPortMarkers = () => {
    if (showPorts) {
      return items.map((item) => (
        <Marker
          icon={blueDot}
          key={item.id}
          position={{ lat: item.latitude, lng: item.longitude }}
          onClick={(_props, markerToSet) => {
            setSelectedMarker(item);
            setActiveMarker(markerToSet);
          }}
        />
      ));
    }
    return null;
  };

  useEffect(() => {
    if (!urlid || !urlstrtDate || !urlenDate || !urlschipRefId) {
      sendDateRange();
      return;
    }
    async function fetchSchipFromUrl() {
      const user = await getUser();

      if (!user) {
        // User is not logged in, initiate login
        login();
        return;
      }

      const apiUrl = `/verwerkings/api/v1/schipLocation/map?sid=${urlid}&sdt=${urlstrtDate}&edt=${urlenDate}`;
      const headers = {
        Authorization: `Bearer ${user.access_token}`
      };

      try {
        const response = await api.get(apiUrl, { headers });
        setResponseData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchSchipFromUrl();
  }, []);

  return (
    <div className="map">
      <div className="controls row" />

      <Map
        initialCenter={{
          lat: 51.8319,
          lng: 4.0485
        }}
        google={props.google}
        style={{ width: '100%', height: '800px', position: 'relative' }}
        className="map"
        zoom={7}
        zoomControlOptions="true"
        onMaptypeid="Terrain"
        options={mapOptions}
        labels="off">
        <div className={`${styles.filterRow} row`}>
          <div className={`${styles.searchBar} col-lg-3 col-md-3 col-sm-3`}>
            <Searchbar
              currentValue={urlschipRefId}
              onSearch={handleSearchFormSubmit}
            />
          </div>
          <div className="col-lg-5 col-md-5 col-sm-5">
            <form className="date-range-form" onSubmit={handleDateFormSubmit}>
              <div className="date-range-dropdown">
                <div>
                  <button
                    className={styles.inputGroupButton}
                    type="submit"
                    onClick={toggleDropdown}>
                    <img
                      className={styles.inputImage}
                      src={calendarImage}
                      alt="calendar"
                    />
                    {selectedRangeLabel}
                  </button>
                </div>

                {dropdownOpen && (
                  <div className="date-picker-container">
                    <DateRange
                      ranges={selectedDateRange}
                      onChange={handleDateRangeChange}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
          <div
            className={`${styles.layersContainer} col-lg-4 col-md-4 col-sm-4`}>
            <div>
              <button
                className={`${styles.layersDropdown} ${
                  showDropdown ? styles.layerFilterOpen : ''
                }`}
                type="button"
                onClick={handleToggleDropdown}>
                <img
                  className={styles.layersImageStyling}
                  src={layersImage}
                  alt="layers"
                />
                Layers
              </button>
              {showDropdown && (
                <div className={styles.layerFilterDropdownItemContainer}>
                  <div className={styles.layersFilterItem}>
                    <img
                      className={styles.layerFilterOptionImage}
                      src={natura200Image}
                      alt="natura2000"
                    />
                    <div className={styles.layerFilterItemLabel}>
                      <label htmlFor="show-restricted">
                        Show Restricted Areas
                      </label>
                    </div>
                    <input
                      type="checkbox"
                      id="show-restricted"
                      checked={showRestrictedAreas}
                      onChange={() => handleSelectOption('show-restricted')}
                    />
                  </div>
                  <div className={styles.layersFilterItem}>
                    <img
                      className={styles.layerFilterOptionImage}
                      src={shipImage}
                      alt="schepen"
                    />
                    <div className={styles.layerFilterItemLabel}>
                      <label htmlFor="show-ships">Schepen</label>
                    </div>
                    <input
                      type="checkbox"
                      id="show-ships"
                      checked={showShips}
                      onChange={() => handleSelectOption('show-ships')}
                    />
                  </div>
                  <div className={styles.layersFilterItem}>
                    <img
                      className={styles.layerFilterOptionImage}
                      src={routesImage}
                      alt="routes"
                    />
                    <div className={styles.layerFilterItemLabel}>
                      <label htmlFor="show-routes">Routes</label>
                    </div>
                    <input
                      type="checkbox"
                      id="show-routes"
                      checked={showRoutes}
                      onChange={() => handleSelectOption('show-routes')}
                    />
                  </div>
                  <div className={styles.layersFilterItem}>
                    <img
                      className={styles.layerFilterOptionImage}
                      src={portImage}
                      alt="havens"
                    />
                    <div className={styles.layerFilterItemLabel}>
                      <label htmlFor="show-ports">Haven</label>
                    </div>
                    <input
                      type="checkbox"
                      id="show-ports"
                      checked={showPorts}
                      onChange={() => handleSelectOption('show-ports')}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {renderRestrictedAreas()}
        {renderCoordinates()}
        {renderPortMarkers()}
        {renderLinesForShips()}

        {selectedMarker ? (
          <InfoWindow
            visible={showInfoWindow}
            marker={activeMarker}
            onCloseClick={() => {
              setSelectedMarker(null);
            }}
            // ...
          >
            {selectedMarker.violationOccurred ? (
              <AlertShipInfo markerClickData={markerClickData} />
            ) : (
              <StandardShipInfo markerClickData={markerClickData} />
            )}
          </InfoWindow>
        ) : null}
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBWXaHtf9LVhHoD57473KQakmdHQ7WRV-g'
})(Maps);
