/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import { Pagination, StepButton } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import whiteMapImage from '../../images/whiteMap.png';
import styles from './DataTable.module.css';
import col from '../../images/column.jpg';
import downloadImage from '../../images/downloads.png';

function DataTable({
  children,
  data,
  columns,
  visibilityObject,
  itemsPerPage = 10,
  onPageChange,
  onLinkClick,
  rowIconColumnDisabled,
  exportClick,
  totalItems,
  pageNumber
}) {
  const [columnVisibility, setColumnVisibility] = useState(visibilityObject);

  const [pageNumbers, setPageNumbers] = useState([]);
  const [showToggleColumnDropdown, setShowToggleColumnDropdown] =
    useState(false);

  const toggleColumn = (columnName) => {
    const newVisibility = {
      ...columnVisibility,
      [columnName]: !columnVisibility[columnName]
    };

    setColumnVisibility({ ...newVisibility });
  };

  const handlePageChange = (newPageNumber) => {
    onPageChange(newPageNumber - 1);
  };

  const handleToggleColumnDropdown = () => {
    setShowToggleColumnDropdown((prev) => !prev);
  };

  useEffect(() => {
    setPageNumbers([]);

    // setCurrentItems(data);
    const pageNumbersToSet = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbersToSet.push(i);
    }

    setPageNumbers(pageNumbersToSet);
  }, [data]);

  const handleGoToPageInput = (event) => {
    event.preventDefault();
    const pageToGoTo = Number(event.target.pageToGoTo.value) - 1;
    if (
      !Number.isNaN(pageToGoTo) &&
      pageToGoTo >= 0 &&
      pageToGoTo <= pageNumbers.length
    ) {
      onPageChange(pageToGoTo);
    }
  };

  useEffect(() => {
    setColumnVisibility(visibilityObject);
  }, [visibilityObject]);

  const renderColumnInvisibilityItem = (column) => {
    const isIcon = !!column?.hasIcon;

    return column.hideable && !isIcon ? (
      <div key={column.id} className={styles.columnVisibilityFilterItem}>
        <div className={styles.columnVisibilityFilterItemLabel}>
          <label htmlFor={column.id}>{column.name}</label>
        </div>
        <input
          type="checkbox"
          id={column.id}
          checked={columnVisibility[column.id]}
          onChange={() => toggleColumn(column.id)}
        />
      </div>
    ) : null;
  };

  const renderColumnItem = (column, item, key) => {
    const isIcon = !!column?.hasIcon;
    const onClick = () => {
      column?.onClickAction?.(item);
    };

    return isIcon ? (
      <button
        className={styles.iconButton}
        // disabled={rowIconColumnDisabled(item)}
        key={`${column.id} ${key}`}
        onClick={() => onClick()}>
        <img
          src={
            rowIconColumnDisabled(item)
              ? column.disabledIcon
              : column.imageSource
          }
          alt={column.alt}
        />
      </button>
    ) : (
      <React.Fragment key={`${column.id} ${key}`}>
        {columnVisibility[column.id] && !isIcon && (
          <td className={`${styles.tableData} ${styles.verticalAlignment}`}>
            <div className={styles.tableDataText}>
              {column?.customFormating
                ? column.customFormating(item[column.id])
                : item[column.id]}
            </div>
          </td>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="w-100 p-5">
      <div className={styles.filterContainer}>
        <div className={styles.columnVisibilityFilterContainer}>
          <button
            className={`${styles.columnVisibilityFilterDropdown} ${
              showToggleColumnDropdown
                ? styles.columnVisibilityFilterDropdownOpen
                : ''
            }`}
            type="button"
            onClick={handleToggleColumnDropdown}>
            <img className={styles.inputImage} src={col} alt="dot" />
            Columns
          </button>
          {showToggleColumnDropdown && (
            <div className={styles.columnVisibilityFilterItemContainer}>
              {columns.map((column) => renderColumnInvisibilityItem(column))}
            </div>
          )}
        </div>
        {children}
      </div>
      <div className={`w-100 ${styles.tableContainer}`}>
        <table className="w-100">
          <thead>
            <tr>
              {columns.map((column) => (
                <React.Fragment key={`table head ${column.name}`}>
                  {columnVisibility[column.id] && (
                    <th className={styles.tableHeader}>{column.name}</th>
                  )}
                </React.Fragment>
              ))}
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const key = `${item.id} ${index}`;

              return (
                <tr className={styles.tableRowStyling} key={`TR ${key}`}>
                  {columns.map((column) => renderColumnItem(column, item, key))}
                  {exportClick !== undefined && (
                    <td
                      className={`${styles.tableActionColumn} ${styles.verticalAlignment}`}>
                      <div className={styles.tableAction}>
                        <img src={downloadImage} alt="white map" />
                        <button
                          className={styles.mapBtn}
                          onClick={() => exportClick(item)}>
                          Export
                        </button>
                      </div>
                    </td>
                  )}

                  <td
                    className={`${styles.tableActionColumn} ${styles.verticalAlignment}`}>
                    <div className={styles.tableActionContainer}>
                      <div className={styles.tableAction}>
                        <img src={whiteMapImage} alt="white map" />
                        <button
                          className={styles.mapBtn}
                          onClick={() => onLinkClick(item)}>
                          Bekijk op kaart
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.paginationContainer}>
        <div>
          <span>
            {itemsPerPage >= data.length
              ? data.length
              : (pageNumber + 1) * itemsPerPage}{' '}
            van {data.length} resultaten
          </span>
        </div>
        <div className={styles.pagination}>
          <Pagination
            count={pageNumbers.length}
            page={pageNumber + 1}
            onChange={(_event, page) => handlePageChange(page)}
          />
        </div>
        <div className={styles.paginationGoToInputContainer}>
          <form onSubmit={handleGoToPageInput}>
            <div className={styles.paginationGoToText}>Go to Page</div>
            <input
              maxLength={4}
              name="pageToGoTo"
              defaultValue={1}
              className={styles.paginationGoToInput}
              type="text"
            />
            <button className={styles.paginationGoToButton} type="submit">
              Go
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DataTable;
