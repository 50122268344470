import axios from 'axios';
import { logout } from './auth_helper';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

export default api;
